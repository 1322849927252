import { useCallback } from "react";
import styled from "styled-components";
import { useHomeController } from "./controller";

export const Home = () => {
  const { surveyResult, captureRef, image, shareImage } = useHomeController();

  const handleClickShareImage = useCallback(() => {
    shareImage();
  }, [shareImage]);

  return (
    <>
      <Container ref={captureRef}>
        <ResultContainer>
          {surveyResult &&
            surveyResult.map(({ question, answer }) => {
              return (
                <div>
                  <h2>{question}</h2>
                </div>
              );
            })}
        </ResultContainer>
        <button onClick={handleClickShareImage} style={{ backgroundColor: "transparent", border: "none" }}>
          <img
            src="https://developers.kakao.com/assets/img/about/logos/kakaotalksharing/kakaotalk_sharing_btn_medium.png"
            alt="카카오톡 공유 보내기 버튼"
          />
        </button>
      </Container>
      {/* <ImageCover src={image} alt="Captured Result" /> */}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  max-width: 440px;
`;

// 내 생각엔 download 버튼이랑 kakao button가 image 보다 zIndex 가 더 높아야 할 것 같다.
const Button = styled.button`
  width: 150px;
  height: 50px;
  border: none;
  border-radius: 12px;
  background-color: pink;
  cursor: pointer;
`;

const ImageCover = styled.img`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: red;
`;
