import dayjs from "dayjs";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GOOGLE_SHEET_DOC_IDS, SHEET_IDS } from "screens/home/home.const";
import { ISurveyResult } from "screens/home/home.type";

export const useHomeGoogleSheetData = () => {
  const [surveyResult, setSurveyResult] = useState<ISurveyResult>(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const uid = params.get("uid");

  console.log(uid, "This is the uid");

  const today = dayjs().day();

  const docId = GOOGLE_SHEET_DOC_IDS[today];
  const sheetId = SHEET_IDS[today];

  const getGoogleSheet = useCallback(async () => {
    const doc = new GoogleSpreadsheet(docId, {
      apiKey: "AIzaSyAS7_v2JpNeiSAdqjNyKEsj3oZa9Kj23zM",
    });
    await doc.loadInfo();

    return doc;
  }, []);

  const fetchGoogleSheet = useCallback(async () => {
    const googleSheet = await getGoogleSheet();
    const sheetsByIdElement = googleSheet.sheetsById[sheetId];

    const rows = await sheetsByIdElement.getRows();
    const columnTitles = sheetsByIdElement.headerValues;

    // console.log(rows);
    // console.log(columnTitles);

    setSurveyResult((prev) => {
      return [
        {
          question: columnTitles[2],
          answer: "",
        },
        {
          question: columnTitles[3],
          answer: "",
        },
        {
          question: columnTitles[4],
          answer: "",
        },
        {
          question: columnTitles[5],
          answer: "",
        },
      ];
    });
  }, [getGoogleSheet]);

  useEffect(() => {
    fetchGoogleSheet();
  }, [fetchGoogleSheet]);

  return { surveyResult };
};
