import html2canvas from "html2canvas";
import { useRef, useState } from "react";
import { useDidMount } from "rooks";

export const useHomeImageManager = () => {
  const captureRef = useRef(null);
  const [image, setImage] = useState<string>("");

  const handleCapture = async () => {
    if (captureRef.current) {
      const canvas = await html2canvas(captureRef.current);
      const imgFromCanvas = canvas.toDataURL("image/png");
      setImage(imgFromCanvas);
    }
  };

  const shareImage = () => {
    if (!image) {
      return;
    }

    const byteString = atob(image.split(",")[1]);
    const mimeString = image.split(",")[0].split(":")[1].split(";")[0];
    const buffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(buffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([buffer], { type: mimeString });
    const file = new File([blob], "captured_image.png", {
      type: mimeString,
    });

    console.log(file);

    window.Kakao.Share.uploadImage({
      file: [file],
    })
      .then((res: any) => {
        const imageUrl = res.infos.original.url;

        window.Kakao.Share.sendDefault({
          objectType: "feed",
          content: {
            title: "연애 회고 플래너(가칭)",
            description: "그/그녀의 답변을 확인해보세요!",
            imageUrl: imageUrl,
            link: {
              mobileWebUrl: imageUrl,
              webUrl: imageUrl,
            },
          },
        });
      })
      .catch((e: any) => {
        console.log("Image upload error: ", e);
      });
  };

  useDidMount(() => {
    handleCapture();
  });

  return { captureRef, image, shareImage };
};
