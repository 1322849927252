const DAY_1_URL =
  "https://docs.google.com/spreadsheets/d/1N6cH43FHery3Tkd6W8SOdgkwPk0j-PMiox4xnej-KoY/edit?usp=drivesdk";
const DAY_2_URL =
  "https://docs.google.com/spreadsheets/d/1eCsQsxUj16PQ_-cGt5mYuM81EdzOnl0gz_rtwzO_TeY/edit?usp=drivesdk";
const DAY_3_URL =
  "https://docs.google.com/spreadsheets/d/1EslyLKsx9gGuHW0bVcqqv9zSuYklrRBQpSjTbiCASd8/edit?usp=drivesdk";
const DAY_4_URL =
  "https://docs.google.com/spreadsheets/d/1y-h8VsUTWlzD4nS0IvMXpsME6Cb3JXpbppvrmSJgrWo/edit?usp=drivesdk";
const DAY_5_URL =
  "https://docs.google.com/spreadsheets/d/1U04_3fdtB_mHqw8rhnzyshfj6TmWrTNcFr6Bpk74pYQ/edit?usp=drivesdk";
const DAY_6_URL =
  "https://docs.google.com/spreadsheets/d/1J9NjFtdSAyV6mTXIa6RMkzty8Zd6ThN4LvdkX9Hh55g/edit?usp=drivesdk";
const DAY_7_URL =
  "https://docs.google.com/spreadsheets/d/1VJC59jkLfZ4kc9DuaAH9e1dx3GeuTNboMzZmoVjvYXM/edit?usp=drivesdk";

const DAY_1_DOC_ID = "1N6cH43FHery3Tkd6W8SOdgkwPk0j-PMiox4xnej-KoY";
const DAY_2_DOC_ID = "1eCsQsxUj16PQ_-cGt5mYuM81EdzOnl0gz_rtwzO_TeY";
const DAY_3_DOC_ID = "1EslyLKsx9gGuHW0bVcqqv9zSuYklrRBQpSjTbiCASd8";
const DAY_4_DOC_ID = "1y-h8VsUTWlzD4nS0IvMXpsME6Cb3JXpbppvrmSJgrWo";
const DAY_5_DOC_ID = "1U04_3fdtB_mHqw8rhnzyshfj6TmWrTNcFr6Bpk74pYQ";
const DAY_6_DOC_ID = "1J9NjFtdSAyV6mTXIa6RMkzty8Zd6ThN4LvdkX9Hh55g";
const DAY_7_DOC_ID = "1VJC59jkLfZ4kc9DuaAH9e1dx3GeuTNboMzZmoVjvYXM";

const DAY_1_SHEET_ID = 1319583923;
const DAY_2_SHEET_ID = 1774232774;
const DAY_3_SHEET_ID = 957911134;
const DAY_4_SHEET_ID = 1657178389;
const DAY_5_SHEET_ID = 204246268;
const DAY_6_SHEET_ID = 402607411;
const DAY_7_SHEET_ID = 1123291482;

export const GOOGLE_SHEETS = {
  1: DAY_1_URL,
  2: DAY_2_URL,
  3: DAY_3_URL,
  4: DAY_4_URL,
  5: DAY_5_URL,
  6: DAY_6_URL,
  0: DAY_7_URL,
};

export const GOOGLE_SHEET_DOC_IDS = {
  1: DAY_1_DOC_ID,
  2: DAY_2_DOC_ID,
  3: DAY_3_DOC_ID,
  4: DAY_4_DOC_ID,
  5: DAY_5_DOC_ID,
  6: DAY_6_DOC_ID,
  0: DAY_7_DOC_ID,
};

export const SHEET_IDS = {
  1: DAY_1_SHEET_ID,
  2: DAY_2_SHEET_ID,
  3: DAY_3_SHEET_ID,
  4: DAY_4_SHEET_ID,
  5: DAY_5_SHEET_ID,
  6: DAY_6_SHEET_ID,
  0: DAY_7_SHEET_ID,
};
